import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { Paperclip, X, FilePdf, FileDoc } from "phosphor-react";
import styles from "./style.module.css";

import {
  AttachmentContent,
  AttachmentDescription,
  AttachmentTitle,
  AttachmentWrapper,
  BrowseLink,
  Card,
  CardGroup,
  Container,
  ContentWrapper,
  Description,
  FileAttachmentContent,
  FileAttachmentWrapper,
  FileLink,
  FileName,
  FileThumbnail,
  Filler,
  Footer,
  FooterText,
  Header,
  HeaderContent,
  HeaderDescription,
  ImageTitle,
  Label,
  MainContent,
  Progress,
  ProgressLabel,
  RemoveFileButton,
  Section,
  Tag,
  TextBlock,
  Title,
  UploadProgressWrapper,
} from "./Styled";
import { useParams } from "react-router-dom";
import { useCarePlan } from "../../../lib/hooks/useCarePlan";

import { Modal } from "../../../components/modal/Modal";
import { AlertContext, BaseContext } from "../../../lib/context/context";
import {
  CarePlanContent,
  SelectedPillarTemplateProps,
} from "../../../lib/interfaces/carePlan";
import { ExitCarePlan } from "../../../components/carePlan/ExitCarePlan";
import { CarePlanStatus } from "../../../components/carePlanStatus/CarePlanStatus";
import { Spinner } from "../../../components/spinner/Spinner";
import { Editor } from "../../../components/editor/Editor";
import { marked } from "marked";
import { careplanPatientDetailFormatDateTime } from "../../../lib/util/date";
import { CarePlanProps } from "../../../lib/interfaces/carePlan";
import { ConfirmPopup } from "../../../components/carePlan/ConfirmPopup";
import { logError } from "../../../lib/util/logger";
import { format, subHours } from "date-fns";
import PublishModal from "../../../components/carePlan/Modal/PublishModal";

export interface AddedFile {
  name: string;
  file: globalThis.File;
}

interface PatientRouteParam {
  userId: string;
}

export enum PublishModalTypes {
  Draft = "Draft",
  Published = "Published",
  Unpublish = "Unpublish",
  Changed = "Changed",
}

export interface publishModalProps {
  isVisible: boolean;
  modalType?: PublishModalTypes;
}

const initalModalValues: publishModalProps = {
  isVisible: false,
};

export const PillarEdit = ({
  patient,
  pillarDatas,
  onClose,
}: CarePlanProps) => {
  const { userId } = useParams<PatientRouteParam>();
  const { pushAlert } = useContext(AlertContext);
  const { updateCarePlan, addCarePlanAttachment } = useCarePlan();

  const controller = new AbortController();
  const { selectedTemplateData } = useContext(BaseContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [duplicateFileUpload, setDuplicateFileUpload] =
    useState<boolean>(false);
  const [addedFiles, setAddedFiles] = useState<AddedFile[]>([]);
  const [pillarDetails, setPillarDetails] =
    useState<SelectedPillarTemplateProps | null>(null);

  const [fileUploadPercentage, setFileUploadPercentage] = useState<number>(0);

  const [attachmentData, setAttachmentData] = useState(
    selectedTemplateData?.content.carePlanContent[0].attachment || null
  );
  const [publishModal, setPublishModal] =
    useState<publishModalProps>(initalModalValues);
  const [initialBody, setInitialBody] = useState<string | undefined>(undefined);
  const [isAttachmentChanged, setIsAttachmentChanged] = useState(false);

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (selectedTemplateData) {
      const carePlanData = selectedTemplateData?.content;

      if (
        carePlanData?.carePlanContent &&
        carePlanData?.carePlanContent?.length > 0
      ) {
        const updatePillarData = async () => {
          // Resolve the content body using marked
          const convertMarkToHTML = carePlanData?.carePlanContent[0]?.body
            ? await marked(carePlanData?.carePlanContent[0]?.body)
            : "";

          setInitialBody(convertMarkToHTML);

          // Create a new object with the resolved body
          const summaryContentWithResolvedBody: CarePlanContent = {
            ...carePlanData?.carePlanContent[0],
            body: convertMarkToHTML, // Replace the original body with the resolved one
          };

          setPillarDetails({
            ...selectedTemplateData,
            content: {
              ...carePlanData,
              carePlanContent: [summaryContentWithResolvedBody], // Use the updated content
            },
          });
        };
        updatePillarData();
      }
    }
  }, [selectedTemplateData]);

  const handleContentChange = (
    field: keyof CarePlanContent,
    value: string | object
  ): void => {
    setPillarDetails((prevState: SelectedPillarTemplateProps | null) => {
      if (!prevState) return prevState;

      const { content } = prevState;
      const { carePlanContent } = content;

      // Ensure carePlanContent exists and has at least one item
      if (!Array.isArray(carePlanContent) || carePlanContent.length === 0) {
        return prevState;
      }

      // Update the first CarePlanContent item immutably
      const updatedCarePlanContent = {
        ...carePlanContent[0],
        [field]: value,
      };

      // Return the new state with updated contentData
      return {
        ...prevState,
        content: {
          ...content,
          carePlanContent: [updatedCarePlanContent],
        },
      };
    });
  };

  const updateCarePlanHandler = async (
    shouldRedirect: boolean = true
  ): Promise<void> => {
    if (
      !pillarDetails ||
      !pillarDetails.pillarId ||
      !pillarDetails.careplanId ||
      !pillarDetails.patientGroupTypeId
    ) {
      pushAlert("Care Plan details are incomplete.", "warning");
      return;
    }

    const { pillarId, careplanId, patientGroupTypeId, content } = pillarDetails;

    // Ensure userId is available
    if (!userId) {
      pushAlert("User ID is missing.", "danger");
      return;
    }

    try {
      const res = await updateCarePlan(
        userId,
        pillarId,
        patientGroupTypeId,
        careplanId,
        content
      );

      if (res && typeof res === "object") {
        if (shouldRedirect) {
          onClose();
        }
        setFileUploadSuccess(false);
        if (!shouldRedirect) {
          setPublishModal({
            isVisible: true,
            modalType: PublishModalTypes.Published,
          });
        } else {
          pushAlert("Care Plan updated successfully.", "success");
        }
      } else {
        pushAlert("Unexpected response from the server.", "danger");
      }
    } catch (error) {
      logError(
        "Error updating Care Plan: ",
        { userId, pillarId, patientGroupTypeId, careplanId, content },
        error as Error
      );
    }
  };

  const [scrolled, setScrolled] = useState(false);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const filePickerRef = useRef<HTMLInputElement>(null);
  const isRedirectRef = useRef(false);

  const handleAddAttachmentClick = (): void => {
    if (filePickerRef.current) {
      filePickerRef.current.click();
    } else {
      pushAlert("Unable to open file picker. Please try again.", "danger");
    }
  };

  const handleFilePick = async (_files: FileList | null) => {
    try {
      const files = _files ? Array.from(_files) : [];
      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB

      if (files.length === 0) {
        setDuplicateFileUpload(true);
        return;
      }

      if (duplicateFileUpload) setDuplicateFileUpload(false);

      const validFiles = files.filter((file) => file.size <= MAX_SIZE);

      if (validFiles.length !== files.length) {
        pushAlert("File must be under 5 MB.", "danger");
      }

      setAddedFiles((prev) => [
        ...prev,
        ...validFiles.map((file) => ({ name: file.name, file })),
      ]);
    } catch (error) {
      logError("Error handling file pick:", { _files }, error as Error);
    }
  };

  const onProgress = (progress: number) => {
    setFileUploadPercentage(progress);
  };

  const handleFileUpload = async () => {
    try {
      const uploadPromises = addedFiles.map(async ({ name, file }) => {
        const dataUrl = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = () => reject(new Error("File reading failed"));
          reader.readAsDataURL(file);
        });

        const timestamp = Date.now();
        const uniqueName = `${userId}_${timestamp}`;
        const res = await addCarePlanAttachment(
          userId,
          uniqueName,
          dataUrl,
          onProgress
        );

        if (res.attachmentUrl) {
          handleContentChange("attachment", {
            attachmentUrl: res.attachmentUrl,
            attachmentName: name,
          });
          return true;
        }
        return false;
      });

      const results = await Promise.all(uploadPromises);

      if (results.every((result) => result)) {
        pushAlert("File added successfully", "success");
        setFileUploadSuccess(true);
      } else {
        pushAlert("File unable to be uploaded. Please try again.", "danger");
      }
    } catch (error) {
      logError("Error during file upload:", { addedFiles }, error as Error);
    } finally {
      setFileUploadPercentage(0);
      setAddedFiles([]);
    }
  };

  useEffect(() => {
    if (
      pillarDetails?.content.carePlanContent[0].attachment?.attachmentUrl &&
      fileUploadSuccess
    ) {
      updateCarePlanHandler(isRedirectRef.current);
    }
  }, [fileUploadSuccess]);

  const getFileNameFromUrl = (url: string) => {
    return url.substring(url.lastIndexOf("/") + 1);
  };

  const handleSave = async (shouldRedirect: boolean = true) => {
    const carePlanContent = selectedTemplateData?.content.carePlanContent[0];
    const title = carePlanContent?.title;
    const body = carePlanContent?.body;
    const newFiles = addedFiles;
    isRedirectRef.current = shouldRedirect;

    if (!title || !body) {
      return;
    }

    try {
      // If there are new files to upload
      if (newFiles.length > 0) {
        await handleFileUpload();
      } else {
        await updateCarePlanHandler(shouldRedirect);
      }
    } catch (error) {
      pushAlert("Failed to save Care Plan. Please try again.", "danger");
      logError(
        "Error to save Care Plan:",
        { carePlanContent, title, body, newFiles },
        error as Error
      );
    }
  };

  const removeAttachment = () => {
    setPillarDetails((prev) => {
      if (!prev) return prev;

      const updatedCarePlanContent = prev.content.carePlanContent.map(
        (item, index) => {
          if (index === 0 && item.attachment) {
            const { attachment, ...rest } = item;
            return rest;
          }
          return item;
        }
      );

      if (
        selectedTemplateData?.content?.carePlanContent[0]?.attachment
          ?.attachmentUrl
      ) {
        setIsAttachmentChanged(true);
      }

      return {
        ...prev,
        content: {
          ...prev.content,
          carePlanContent: updatedCarePlanContent,
        },
      };
    });
  };

  const handleCloseModal = () => {
    setPublishModal(initalModalValues);
  };

  const handleOpenModal = async (type: PublishModalTypes) => {
    if (type !== "Unpublish") {
      await handleSave(false);
    } else {
      setPublishModal({ isVisible: true, modalType: type });
    }
  };

  return (
    <>
      {showExitModal && (
        <Modal
          visible={showExitModal}
          onCloseModal={() => {}}
          title="Save changes before exiting?"
          dismissable={false}
          className={styles.modalExit}
        >
          <ExitCarePlan
            onSubmit={() => {
              if (addedFiles.length > 0) {
                handleFileUpload();
              } else {
                updateCarePlanHandler();
              }
            }}
            exitWithOutSave={() => {
              setShowExitModal(false);
              onClose();
            }}
            onCancel={() => setShowExitModal(false)}
            isLoading={isLoading}
            disabled={
              pillarDetails?.content.carePlanContent[0]?.body &&
              (pillarDetails?.content.carePlanContent[0]?.body !==
                initialBody ||
                addedFiles?.length > 0 ||
                isAttachmentChanged)
                ? false
                : true
            }
          />
        </Modal>
      )}

      {showConfirmModal && (
        <Modal
          visible={showConfirmModal}
          onCloseModal={() => {}}
          title="Are you sure you want to remove the attachment?"
          dismissable={false}
          className={styles.modalExit}
        >
          <ConfirmPopup
            onSubmit={() => {
              setAddedFiles([]);
              setFileUploadPercentage(0);
              setShowConfirmModal(false);
              if (attachmentData !== null) {
                setAttachmentData(null);
                removeAttachment();
              }
            }}
            onCancel={() => {
              setShowConfirmModal(false);
            }}
          />
        </Modal>
      )}

      <PublishModal
        isVisible={publishModal.isVisible}
        modalType={publishModal.modalType}
        onClose={handleCloseModal}
        patient={patient}
        carePlanDetail={selectedTemplateData || null}
      />

      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          <Container>
            <Header>
              <HeaderContent>
                <HeaderDescription scrolled={scrolled}>
                  {selectedTemplateData?.status && (
                    <Tag>
                      <CarePlanStatus status={selectedTemplateData?.status} />
                    </Tag>
                  )}

                  <HeaderDescription scrolled={scrolled}>
                    <Title scrolled={scrolled}>
                      {`${selectedTemplateData?.pillarName} : ${selectedTemplateData?.patientGroupTypeName}`}
                    </Title>
                    {patient?.name && (
                      <Description>
                        {`${patient.name}`} • {patient.assignedSex} •{" "}
                        {patient?.dateOfBirth &&
                          careplanPatientDetailFormatDateTime(
                            patient?.dateOfBirth
                          )}{" "}
                        {patient?.mrnId && <> • {patient?.mrnId}</>}
                      </Description>
                    )}
                  </HeaderDescription>
                </HeaderDescription>
              </HeaderContent>
            </Header>
          </Container>

          <ContentWrapper ref={contentWrapperRef}>
            <MainContent>
              <CardGroup>
                <Label>Pillar Image</Label>
                <Card>
                  <table width="100%">
                    <tr>
                      <td width="220px">
                        <img
                          src={selectedTemplateData?.mediaUrl}
                          alt="Pillar Image"
                        />
                      </td>
                      <td valign="middle">
                        <ImageTitle>
                          {selectedTemplateData?.mediaUrl?.split("/").pop()}
                        </ImageTitle>
                      </td>
                    </tr>
                  </table>
                </Card>
                <Section>
                  <TextBlock>
                    <Label>Intro Text Block</Label>

                    <Editor
                      key={0}
                      value={
                        pillarDetails?.content?.carePlanContent[0]?.body || ""
                      }
                      onChange={(value) => {
                        handleContentChange("body", value);
                      }}
                    />

                    <Label>Upload and Attach File</Label>

                    {!addedFiles.length && !attachmentData ? (
                      <AttachmentWrapper>
                        {selectedTemplateData?.content?.carePlanContent[0]
                          .attachment?.attachmentUrl &&
                          selectedTemplateData?.content?.carePlanContent[0].attachment?.attachmentUrl.includes(
                            "doc"
                          ) && (
                            <FileLink>
                              <FileDoc size={20} />
                              <span
                                onClick={() =>
                                  window.open(
                                    `${selectedTemplateData?.content?.carePlanContent[0].attachment?.attachmentUrl}`,
                                    "_blank"
                                  )
                                }
                              >
                                {getFileNameFromUrl(
                                  selectedTemplateData?.content
                                    ?.carePlanContent[0].attachment
                                    ?.attachmentUrl
                                )}
                              </span>
                            </FileLink>
                          )}
                        {selectedTemplateData?.content?.carePlanContent[0]
                          .attachment?.attachmentUrl &&
                          selectedTemplateData?.content?.carePlanContent[0].attachment?.attachmentUrl.includes(
                            "pdf"
                          ) && (
                            <FileLink>
                              <FilePdf size={20} />
                              <span
                                onClick={() =>
                                  window.open(
                                    `${selectedTemplateData?.content?.carePlanContent[0].attachment?.attachmentUrl}`,
                                    "_blank"
                                  )
                                }
                              >
                                {getFileNameFromUrl(
                                  selectedTemplateData?.content
                                    ?.carePlanContent[0].attachment
                                    ?.attachmentUrl
                                )}
                              </span>
                            </FileLink>
                          )}

                        <div
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          onDrop={(e) => {
                            e.preventDefault();
                            const files = e.dataTransfer.files;
                            const allowedTypes = [
                              "application/msword",
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                              "application/pdf",
                              "image/png",
                              "image/jpeg",
                              "image/jpg",
                            ];

                            if (files.length > 1) {
                              pushAlert(
                                "Only one file can be attached.",
                                "danger"
                              );
                              return;
                            }

                            const file = files[0];
                            if (!allowedTypes.includes(file.type)) {
                              pushAlert(
                                "Only one DOC, PDF, PNG, or JPEG file is allowed.",
                                "danger"
                              );
                              return;
                            }

                            handleFilePick(files);
                          }}
                        >
                          <AttachmentContent>
                            <input
                              ref={filePickerRef}
                              type="file"
                              accept=".doc,.docx,.pdf,.png,.jpeg,.jpg"
                              style={{ display: "none" }}
                              onChange={(e) => handleFilePick(e.target.files)}
                              multiple={false}
                            />

                            <Button
                              type="secondary-gray"
                              onClick={handleAddAttachmentClick}
                              Icon={Paperclip}
                              disabled={addedFiles?.length ? true : false}
                              className={styles.attachmentButton}
                            />
                            <AttachmentTitle>
                              Drop your file here or{" "}
                              <BrowseLink> browse</BrowseLink>
                            </AttachmentTitle>

                            <AttachmentDescription>
                              Supported formats: DOC, PDF, PNG, JPEG
                            </AttachmentDescription>
                          </AttachmentContent>
                        </div>
                      </AttachmentWrapper>
                    ) : (
                      <>
                        {attachmentData && !addedFiles.length ? (
                          <>
                            <FileAttachmentWrapper>
                              <FileAttachmentContent>
                                <FileThumbnail>
                                  <Paperclip size="25px" />
                                </FileThumbnail>
                                <FileName
                                  onClick={() => {
                                    if (addedFiles.length === 0) {
                                      window.open(
                                        attachmentData?.attachmentSignedUrl,
                                        "_blank"
                                      );
                                    }
                                  }}
                                >
                                  {attachmentData?.attachmentName}
                                </FileName>
                                <RemoveFileButton
                                  onClick={() => {
                                    setShowConfirmModal(true);
                                  }}
                                >
                                  <X />
                                </RemoveFileButton>
                              </FileAttachmentContent>
                            </FileAttachmentWrapper>
                          </>
                        ) : (
                          addedFiles.map((_file, index) => (
                            <React.Fragment key={index}>
                              <FileAttachmentWrapper>
                                <FileAttachmentContent>
                                  <FileThumbnail>
                                    <Paperclip size="25px" />
                                  </FileThumbnail>

                                  <FileName>{_file.name}</FileName>

                                  {fileUploadPercentage > 0 && (
                                    <UploadProgressWrapper>
                                      <Progress>
                                        <Filler
                                          progress={fileUploadPercentage}
                                        ></Filler>
                                      </Progress>
                                      <ProgressLabel>{`${fileUploadPercentage}%`}</ProgressLabel>
                                    </UploadProgressWrapper>
                                  )}

                                  <RemoveFileButton
                                    onClick={() => {
                                      setShowConfirmModal(true);
                                    }}
                                  >
                                    <X />
                                  </RemoveFileButton>
                                </FileAttachmentContent>
                              </FileAttachmentWrapper>
                            </React.Fragment>
                          ))
                        )}
                      </>
                    )}
                  </TextBlock>
                </Section>
              </CardGroup>
            </MainContent>
            <br />

            <Footer>
              <Button
                size="medium"
                type="secondary"
                label="Exit"
                iconPosition="left"
                onClick={() => setShowExitModal(true)}
                className={styles.button}
              />
              <ButtonGroup>
                <>
                  {selectedTemplateData && selectedTemplateData?.updatedAt && (
                    <FooterText>
                      Last saved{" "}
                      {`${format(
                        subHours(
                          new Date(
                            selectedTemplateData?.updatedAt.split(" ").join("T")
                          ),
                          7
                        ),
                        "MMM dd yyyy"
                      )}, ${format(
                        subHours(
                          new Date(
                            selectedTemplateData?.updatedAt.split(" ").join("T")
                          ),
                          7
                        ),
                        "h:mma"
                      ).toLowerCase()}`}
                    </FooterText>
                  )}
                </>
                <Button
                  size="medium"
                  type="secondary"
                  label={`Save & Do Not ${
                    selectedTemplateData?.status === "draft" ||
                    selectedTemplateData?.status === "changed"
                      ? "Publish"
                      : "Unpublish"
                  }`}
                  iconPosition="left"
                  onClick={handleSave}
                  disabled={
                    pillarDetails?.content.carePlanContent[0]?.body &&
                    (pillarDetails?.content.carePlanContent[0]?.body !==
                      initialBody ||
                      addedFiles?.length > 0 ||
                      isAttachmentChanged)
                      ? false
                      : true
                  }
                  className={styles.button}
                />
                {selectedTemplateData?.status === "draft" ||
                selectedTemplateData?.status === "changed" ? (
                  <Button
                    size="medium"
                    label="Publish..."
                    iconPosition="left"
                    onClick={() => handleOpenModal(PublishModalTypes.Published)}
                    disabled={
                      pillarDetails?.content.carePlanContent[0]?.body
                        ? false
                        : true
                    }
                    className={styles.button}
                  />
                ) : (
                  <Button
                    size="medium"
                    label="Unpublish..."
                    iconPosition="left"
                    onClick={() => handleOpenModal(PublishModalTypes.Unpublish)}
                    disabled={false}
                    className={styles.button}
                  />
                )}
              </ButtonGroup>
            </Footer>
          </ContentWrapper>
        </>
      )}
    </>
  );
};
